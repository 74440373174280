table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
}

td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    width: 80px;
    white-space: nowrap;
}

tr:hover {
    background: #f7e3be;
}

.foot {
    font-weight: bold;
}

.content {
    margin: 50px 10%;
    display: flex;
}

.stats {
    margin-left: 50px;

}

.filters {
    float: left;
}

.bio {
    width: 60%;
    float: left;
}

.bio tr:hover {
    background: white;
}

.batting {
    width: 100%;
    float: left;
}

.pitching {
    width: 100%;
    float: left;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center
}

.bio-container{
  width: 450px;
  margin-right: 10px;
}

img {
  max-width: 100%
}

.image {
  flex-basis: 23%
}

.text {
  font-size: 18px;
  padding-top: 4px;
  padding-bottom: 8px;
  width: 250px;
  max-width: 100%;
}
